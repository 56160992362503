import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.language_default);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let language = localStorage.getItem('language');
    if (language) {
      translate.use(language);
    } else {
      localStorage.setItem('language', environment.language_default);
    }
  }
}
