/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Offer } from '../models/offer';


@Injectable()
export class ManagerOffersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  listOfferResponse(params: ManagerOffersService.ListOfferParams): Observable<HttpResponse<Offer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/offer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer[] = null;
        _body = _resp.body as Offer[]
        return _resp.clone({body: _body}) as HttpResponse<Offer[]>;
      })
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  listOffer(params: ManagerOffersService.ListOfferParams): Observable<Offer[]> {
    return this.listOfferResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  createOfferResponse(body?: Offer): Observable<HttpResponse<Offer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/offer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer = null;
        _body = _resp.body as Offer
        return _resp.clone({body: _body}) as HttpResponse<Offer>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createOffer(body?: Offer): Observable<Offer> {
    return this.createOfferResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  updateOfferResponse(body?: Offer): Observable<HttpResponse<Offer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/offer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer = null;
        _body = _resp.body as Offer
        return _resp.clone({body: _body}) as HttpResponse<Offer>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  updateOffer(body?: Offer): Observable<Offer> {
    return this.updateOfferResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param offerId - undefined
   */
  getOfferByIdResponse(offerId: number): Observable<HttpResponse<Offer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/offer/${offerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer = null;
        _body = _resp.body as Offer
        return _resp.clone({body: _body}) as HttpResponse<Offer>;
      })
    );
  }

  /**
   * @param offerId - undefined
   */
  getOfferById(offerId: number): Observable<Offer> {
    return this.getOfferByIdResponse(offerId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param offerId - undefined
   */
  deleteOfferResponse(offerId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/offer/${offerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param offerId - undefined
   */
  deleteOffer(offerId: number): Observable<void> {
    return this.deleteOfferResponse(offerId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  listOfferAppResponse(): Observable<HttpResponse<Offer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/app/offer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer[] = null;
        _body = _resp.body as Offer[]
        return _resp.clone({body: _body}) as HttpResponse<Offer[]>;
      })
    );
  }

  /**
   */
  listOfferApp(): Observable<Offer[]> {
    return this.listOfferAppResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  getAnalyticsResponse(): Observable<HttpResponse<Offer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/offer/getAnalytics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Offer[] = null;
        _body = _resp.body as Offer[]
        return _resp.clone({body: _body}) as HttpResponse<Offer[]>;
      })
    );
  }

  /**
   */
  getAnalytics(): Observable<Offer[]> {
    return this.getAnalyticsResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module ManagerOffersService {
  export interface ListOfferParams {
    page?: number;
    limit?: number;
  }
}
