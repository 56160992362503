import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../api/services';
import { Login } from '../../api/models';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  public forgot: Login;

  constructor(
    private apiAuths: AuthService,
    private router: Router,
    private translate: TranslateService
  ) { 
    this.forgot = new Login();
  }

  ngOnInit() {
  }

  onForgot() {
    if (!this.forgot.email) {
      swal(this.translate.instant('SWAL_WARN'), 'Email require', 'warning');
      return;
    }
    this.apiAuths.forgotPass(this.forgot).subscribe(
      res => {
        if (res.status == 200) {
          swal({
            title: this.translate.instant('LBL_SEND_SUCCESS'),
            text: this.translate.instant('LBL_NOTIFI_SEND_MAIL'),
            type: "success"
            }).then(okay => {
              if (okay) {
                this.router.navigate(['auth/signin']);
              }
            });
        } else {
          swal({ type: 'error', title: this.translate.instant('LBL_SEND_FAILD'), text: res.msg });
        }
      },
      err => {
        swal({ type: 'error', title: this.translate.instant('SWAL_UPDATE_FAILD'), text: err.error });
      }
    );
  }
}