// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pagination_limit: 10,
  pagination_size: 5,
  page: [
    {'key': 'user', 'name': 'LBL_USER'},
    {'key': 'setting', 'name': 'LBL_SETTING'},
    {'key': 'company', 'name': 'LBL_COMPANY'},
    {'key': 'procurement', 'name': 'procurement'},
    {'key': 'paper', 'name': 'lbl_paper'}
  ],
  marker_icon: {
    scaledSize: {height:40, width:40},
    url: 'assets/img/lyreco.gif'
  },
  default_map: {
      lat: 48.853213719969915,
      lng: 2.345389296031499,
      zoom: 14
  },
  center_map: {
      lat: 48.8566969,
      lng: 2.3514616,
      zoom: 13
  },
  key_map: 'AIzaSyAxGyoplsIADvSC-EDAFf5wAcnVln3Y08M',
  column: {
    sort: "fa fa-sort",
    asc_sort: 'fa fa-sort-asc',
    desc_sort: 'fa fa-sort-desc',
    asc: 'asc',
    desc: 'desc',
    name: 'name',
    refarticle_id: 'refarticle_id',
    company_name: 'company_name',
    company_code: 'company_code'
  },
  languages: [
      {
          name: 'French',
          code: 'fr'
      },
      {
          name: 'English',
          code: 'en'
      }
  ],
  language_default: 'fr',
  logo: 'assets/img/lyreco.png',
  no_avatar: 'assets/img/no_avatar.png',
  api: 'https://dev.lyreco.dfm-europe.com'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.