/* tslint:disable */

/**
 */
export class DeviceSearch {
    company_id?: number;
    provisioning?: number;
    offer_id?: number;
    refarticle_id?: number;
    status_id?: number;
    device_id?: number;
    type?: string;
    email?: string;
    page?: number;
    limit?: number;
    public?: number;
    columnSort?: string;
    typeSort?: string;
}
