/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Procurement } from '../models/procurement';
import { DeviceSearch } from '../models/device-search';
import { TypeAny } from '../models/type-any';


@Injectable()
export class AdminProcurementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - Search procurement
   */
  procurementOptResponse(body?: Procurement): Observable<HttpResponse<Procurement[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/procurement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Procurement[] = null;
        _body = _resp.body as Procurement[]
        return _resp.clone({body: _body}) as HttpResponse<Procurement[]>;
      })
    );
  }

  /**
   * @param body - Search procurement
   */
  procurementOpt(body?: Procurement): Observable<Procurement[]> {
    return this.procurementOptResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  updateProcurementResponse(body?: Procurement): Observable<HttpResponse<Procurement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/admin/procurement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Procurement = null;
        _body = _resp.body as Procurement
        return _resp.clone({body: _body}) as HttpResponse<Procurement>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  updateProcurement(body?: Procurement): Observable<Procurement> {
    return this.updateProcurementResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - All procurement
   */
  procurementAllResponse(body?: DeviceSearch): Observable<HttpResponse<Procurement[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/procurement/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Procurement[] = null;
        _body = _resp.body as Procurement[]
        return _resp.clone({body: _body}) as HttpResponse<Procurement[]>;
      })
    );
  }

  /**
   * @param body - All procurement
   */
  procurementAll(body?: DeviceSearch): Observable<Procurement[]> {
    return this.procurementAllResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Export procurement
   */
  exportProcurementResponse(body?: Procurement): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/procurement/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param body - Export procurement
   */
  exportProcurement(body?: Procurement): Observable<string> {
    return this.exportProcurementResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - procurement send mail
   */
  procurementSendMailResponse(body?: Procurement): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/procurement/send-mail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - procurement send mail
   */
  procurementSendMail(body?: Procurement): Observable<void> {
    return this.procurementSendMailResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  scheduleTaskResponse(): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/procurement/schedule-task`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   */
  scheduleTask(): Observable<void> {
    return this.scheduleTaskResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  autoSaveResponse(): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/procurement/auto-save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   */
  autoSave(): Observable<TypeAny> {
    return this.autoSaveResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module AdminProcurementsService {
}
