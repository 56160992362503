/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Paper } from '../models/paper';
import { TypeAny } from '../models/type-any';


@Injectable()
export class ManagerPapersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - undefined
   */
  createPaperResponse(body?: Paper): Observable<HttpResponse<Paper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/paper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Paper = null;
        _body = _resp.body as Paper
        return _resp.clone({body: _body}) as HttpResponse<Paper>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createPaper(body?: Paper): Observable<Paper> {
    return this.createPaperResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Delete paper object
   */
  deletePaperResponse(body?: TypeAny): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/paper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - Delete paper object
   */
  deletePaper(body?: TypeAny): Observable<void> {
    return this.deletePaperResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  updatePaperResponse(body?: Paper): Observable<HttpResponse<Paper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/paper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Paper = null;
        _body = _resp.body as Paper
        return _resp.clone({body: _body}) as HttpResponse<Paper>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  updatePaper(body?: Paper): Observable<Paper> {
    return this.updatePaperResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param paperId - undefined
   */
  getPaperByIdResponse(paperId: number): Observable<HttpResponse<Paper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/paper/${paperId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Paper = null;
        _body = _resp.body as Paper
        return _resp.clone({body: _body}) as HttpResponse<Paper>;
      })
    );
  }

  /**
   * @param paperId - undefined
   */
  getPaperById(paperId: number): Observable<Paper> {
    return this.getPaperByIdResponse(paperId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  exportPaperResponse(): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/paper/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   */
  exportPaper(): Observable<string> {
    return this.exportPaperResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Import paper object
   */
  importPaperResponse(body?: TypeAny): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/paper/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - Import paper object
   */
  importPaper(body?: TypeAny): Observable<void> {
    return this.importPaperResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Search Paper object
   */
  listPaperResponse(body?: Paper): Observable<HttpResponse<Paper[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/paper/find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Paper[] = null;
        _body = _resp.body as Paper[]
        return _resp.clone({body: _body}) as HttpResponse<Paper[]>;
      })
    );
  }

  /**
   * @param body - Search Paper object
   */
  listPaper(body?: Paper): Observable<Paper[]> {
    return this.listPaperResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  listPaperAppResponse(): Observable<HttpResponse<Paper[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/app/paper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Paper[] = null;
        _body = _resp.body as Paper[]
        return _resp.clone({body: _body}) as HttpResponse<Paper[]>;
      })
    );
  }

  /**
   */
  listPaperApp(): Observable<Paper[]> {
    return this.listPaperAppResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module ManagerPapersService {
}
