/* tslint:disable */
import { Company } from './company';

/**
 */
export class Paper {
    id?: number;
    name?: string;
    refarticle_id?: string;
    sale_unit?: string;
    qty_sale_unit?: number;
    qty_product?: number;
    paper_id?: string;
    compagny?: string;
    company_id?: number;
    company?: Company;
    created_at?: string;
    updated_at?: string;
    page?: number;
    limit?: number;
    columnSort?: string;
    typeSort?: string;
}
