/* tslint:disable */
import { Role } from './role';

/**
 */
export class Permission {
    id?: number;
    role_id?: number;
    role?: Role;
    key?: string;
    edit?: number;
    created_at?: string;
    updated_at?: string;
}
