/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Company } from '../models/company';
import { idArr } from '../models/id-arr';
import { TypeAny } from '../models/type-any';


@Injectable()
export class AdminCompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - undefined
   */
  createCompanyResponse(body?: Company): Observable<HttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Company = null;
        _body = _resp.body as Company
        return _resp.clone({body: _body}) as HttpResponse<Company>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createCompany(body?: Company): Observable<Company> {
    return this.createCompanyResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  updateCompanyResponse(body?: Company): Observable<HttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/admin/company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Company = null;
        _body = _resp.body as Company
        return _resp.clone({body: _body}) as HttpResponse<Company>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  updateCompany(body?: Company): Observable<Company> {
    return this.updateCompanyResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param companyId - undefined
   */
  getCompanyByIdResponse(companyId: number): Observable<HttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/company/${companyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Company = null;
        _body = _resp.body as Company
        return _resp.clone({body: _body}) as HttpResponse<Company>;
      })
    );
  }

  /**
   * @param companyId - undefined
   */
  getCompanyById(companyId: number): Observable<Company> {
    return this.getCompanyByIdResponse(companyId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param companyId - undefined
   */
  deleteCompanyResponse(companyId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/admin/company/${companyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param companyId - undefined
   */
  deleteCompany(companyId: number): Observable<void> {
    return this.deleteCompanyResponse(companyId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  listCompanyAllResponse(): Observable<HttpResponse<Company[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/company/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Company[] = null;
        _body = _resp.body as Company[]
        return _resp.clone({body: _body}) as HttpResponse<Company[]>;
      })
    );
  }

  /**
   */
  listCompanyAll(): Observable<Company[]> {
    return this.listCompanyAllResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - DeleteAll companies object
   */
  deleteAllCompanyResponse(body?: idArr): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/company/deleteall`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - DeleteAll companies object
   */
  deleteAllCompany(body?: idArr): Observable<void> {
    return this.deleteAllCompanyResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  exportCompanyResponse(): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/company/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   */
  exportCompany(): Observable<string> {
    return this.exportCompanyResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Import company object
   */
  importCompanyResponse(body?: TypeAny): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/company/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - Import company object
   */
  importCompany(body?: TypeAny): Observable<void> {
    return this.importCompanyResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Search Company object
   */
  searchCompanyResponse(body?: Company): Observable<HttpResponse<Company[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/company/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Company[] = null;
        _body = _resp.body as Company[]
        return _resp.clone({body: _body}) as HttpResponse<Company[]>;
      })
    );
  }

  /**
   * @param body - Search Company object
   */
  searchCompany(body?: Company): Observable<Company[]> {
    return this.searchCompanyResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module AdminCompaniesService {
}
