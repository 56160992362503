/* tslint:disable */
import { Company } from './company';

/**
 */
export class Stock {
    id?: number;
    name?: string;
    company_id?: number;
    company?: Company;
    created_at?: string;
    updated_at?: string;
}
