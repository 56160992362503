/* tslint:disable */
import { Device } from './device';

/**
 */
export class SendDownlink {
    data_downlink?: Device[];
    payload_downlink?: string;
    port_downlink?: number;
}
