import { Component, OnInit } from '@angular/core';
import { Offer, Procurement } from '../../api/models';
import { AdminProcurementsService, AdminOffersService, ManagerProcurementsService, ManagerOffersService } from '../../api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {

  public user: any = null;
  public avatar: any;
  public procurements: Procurement[] = [];
  public procurements_all: Procurement[] = [];
  public option_search: Procurement;
  public offers: Offer[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private adminProcurement: AdminProcurementsService,
    private apiOffer: AdminOffersService,
    private managerProcurement: ManagerProcurementsService,
    private managerOffer: ManagerOffersService
  ) {
    this.option_search = new Procurement();
    this.option_search.type_account = '';
    this.option_search.type = 'tomorrow';
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.avatar = environment.no_avatar;
    this.getProcurementAll();
    this.getOffers();
  }

  getProcurementAll() {
    if (!this.user.company) {
      this.adminProcurement.procurementOpt(this.option_search).subscribe(
        resp => {
          this.procurements = resp;
          this.procurements_all = resp;
        }
      );
    } else {
      this.managerProcurement.procurementOpt(this.option_search).subscribe(
        resp => {
          this.procurements = resp;
          this.procurements_all = resp;
        }
      );
    }
  }

  getOffers() {
    if (!this.user.company) {
      this.apiOffer.listOffer({}).subscribe(
        res => {
          this.offers = res;
        }
      );
    } else {
      this.managerOffer.listOffer({}).subscribe(
        res => {
          this.offers = res;
        }
      );
    }
  }

  optionFind() {
    if (!this.option_search.type_account) {
      this.procurements = this.procurements_all;
    } else {
      this.procurements = this.procurements_all.filter(item => item.type_account == this.option_search.type_account);
    }
  }

}
