/* tslint:disable */
import { User } from './user';
import { Company } from './company';
import { Status } from './status';
import { Paper } from './paper';
import { Offer } from './offer';
import { Stock } from './stock';
import { Position } from './position';

/**
 */
export class Device {
    id?: number;
    name?: string;
    dev_eui?: string;
    app_eui?: string;
    app_key?: string;
    lat?: number;
    lng?: number;
    location?: string;
    battery?: number;
    alert?: {};
    distance?: number;
    distanceREF?: number;
    distanceREF_modify?: number;
    created_at?: string;
    updated_at?: string;
    last_uplink?: string;
    device_network_id?: string;
    user?: User;
    group_id?: number;
    company_id?: number;
    company?: Company;
    profile_id?: number;
    status_id?: number;
    status?: Status;
    paper_id?: number;
    paper?: Paper;
    offer_id?: number;
    offer?: Offer;
    stock_id?: number;
    stock?: Stock;
    refarticle_id?: number;
    refarticle?: Paper;
    position_id?: number;
    number_column?: number;
    position?: Position;
    max_stock?: number;
    alert_threshold?: number;
    provisioning?: number;
    changeover_day?: string;
    unit?: string;
    server_type?: number;
    level?: number;
    stock_level?: number;
    batery_percent?: number;
    public?: number;
    company_name?: string;
    company_code?: string;
}
