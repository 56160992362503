import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { Router, NavigationEnd } from '@angular/router';
import { Http } from '@angular/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  dataRefresher: any;

  constructor(
    private router: Router,
    private http: Http
  ) {
    //this.API_KEY = 'AIzaSyCcL1uP1e6wUohluzq3Y818bCT5dbRYINU';
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (typeof this.dataRefresher !== 'undefined') {
          clearInterval(this.dataRefresher);
          this.dataRefresher = null;
        }
      }

    });
  }

  public getMapGeocodeApiLink() {
    return 'https://maps.googleapis.com/maps/api/geocode/json?sensor=false&key=' + environment.key_map;
  }

  // Return false if field input is null or empty;
  public isNullOrEmpty(input) {
    if (input == undefined) {
      return true;
    } else if (input == '') {
      return true;
    }
    return false;
  }

  public isEmailInvalid(input) {
    if (input.match(/[^ @]*@[^ @]*/)) {
      return false;
    }
    return true;
  }

  public isNotNumber(input) {
    return isNaN(input);
  }

  public isLatLngValid(lat, lng) {
    if (!this.isNotNumber(+lat) && !this.isNotNumber(+lng)) {
      if (
        +lat <= 180 &&
        +lat >= -180 &&
        +lng <= 90 &&
        +lng >= -90) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public getInfoByLatLng(lat, lng, obj) {
    const geocoderLink = this.getMapGeocodeApiLink() + '&latlng=' + lat + ',' + lng;
    //console.log(geocoderLink);
    this.http.get(geocoderLink).subscribe(
      response => {
        const status_resp = (JSON.parse(response['_body']))['status'];
        const resp = {
          city_code: '',
          city_name: '',
          address: ''
        };
        if (status_resp !== 'ZERO_RESULTS') {
          const map_data = (JSON.parse(response['_body']))['results'][0];
          //console.log(map_data);
          const address_components = map_data['address_components'];
          address_components.forEach(ele => {
            if (ele['types'].indexOf('locality') > -1) {
              resp.city_name = ele['long_name'];
            }
            if (ele['types'].indexOf('postal_code') > -1) {
              resp.city_code = ele['long_name'];
            }
          });
          //resp.city_code = map_data['place_id'];
          resp.address = map_data['formatted_address'];
          obj.city_code = resp.city_code;
          obj.city_name = resp.city_name;
          obj.address = resp.address;
          obj.location = resp.address;
          //console.log(address_components);
        }
        //console.log(obj);
      },
      error => {
        return;
      }
    );
  }

  public sortBy(fieldSort: any, iconSort: any) {
    let promise = new Promise((resolve, reject) => {
      let res = { iconSort: '', columnSort: '', typeSort: '' }
      if (iconSort == environment.column.sort) res.iconSort = environment.column.asc_sort;
      else {
        if (iconSort == environment.column.asc_sort) res.iconSort = environment.column.desc_sort;
        else res.iconSort = environment.column.asc_sort;
      }
      res.columnSort = fieldSort;
      res.typeSort = iconSort == environment.column.asc_sort ? environment.column.desc : environment.column.asc;
      resolve(res);
    })
    return promise;
  }
}
