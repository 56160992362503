import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manager-layout',
  templateUrl: './manager-layout.component.html',
  styleUrls: ['./manager-layout.component.css']
})
export class ManagerLayoutComponent implements OnInit {

  public user: any = null;
  public avatar: any;
  public current_lang: any = environment.language_default;
  public languages: any[] = [];
  public logo_web: any;
  public menu_settting: any = false;

  constructor() { }

  ngOnInit() {
    this.getLanguage();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.avatar = environment.no_avatar;
    this.logo_web = environment.logo;
    this.languages = environment.languages.filter(item => item.code != this.current_lang);
  }

  getLanguage() {
    let lang = localStorage.getItem('language');
    if (lang) 
      this.current_lang = lang;
  }

  changeLanguage(lang) {
    localStorage.setItem('language', lang);
    window.location.reload(true);
  }

  groupMenu(event) {
    if (event == 'setting') {
      this.menu_settting = !this.menu_settting;
    } else {
      this.menu_settting = false;
    }
    // if (event == 'device') {
    //   this.device_child = !this.device_child;
    // } else {
    //   this.device_child = false;
    // }
  }

  childMenu(event) {
    if (event == 'setting') {
      this.menu_settting = !this.menu_settting;
    } else {
      this.menu_settting = false;
    }
  }

}
