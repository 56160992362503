/* tslint:disable */
import { Role } from './role';
import { Company } from './company';
import { Permission } from './permission';

/**
 */
export class User {
    id?: number;
    role_id?: number;
    role?: Role;
    company_id?: number;
    company?: Company;
    permission?: Permission;
    email?: string;
    password?: string;
    confirm_password?: string;
    full_name?: string;
    address?: string;
    phone?: string;
    email_recovery?: string;
    created_at?: string;
    updated_at?: string;
}
