import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { AdminCompaniesService } from './services/admin-companies.service';
import { AdminDashboardService } from './services/admin-dashboard.service';
import { AdminDevicesService } from './services/admin-devices.service';
import { AdminEmailsService } from './services/admin-emails.service';
import { AdminOffersService } from './services/admin-offers.service';
import { AdminPapersService } from './services/admin-papers.service';
import { AdminPermissionsService } from './services/admin-permissions.service';
import { AdminProcurementsService } from './services/admin-procurements.service';
import { AdminRefarticlesService } from './services/admin-refarticles.service';
import { AdminRolesService } from './services/admin-roles.service';
import { AdminStatusService } from './services/admin-status.service';
import { AdminStocksService } from './services/admin-stocks.service';
import { AdminUsersService } from './services/admin-users.service';
import { AuthService } from './services/auth.service';
import { ManagerCompaniesService } from './services/manager-companies.service';
import { ManagerDashboardService } from './services/manager-dashboard.service';
import { ManagerDevicesService } from './services/manager-devices.service';
import { ManagerEmailsService } from './services/manager-emails.service';
import { ManagerOffersService } from './services/manager-offers.service';
import { ManagerPapersService } from './services/manager-papers.service';
import { ManagerPositionsService } from './services/manager-positions.service';
import { ManagerProcurementsService } from './services/manager-procurements.service';
import { ManagerRefarticlesService } from './services/manager-refarticles.service';
import { ManagerStocksService } from './services/manager-stocks.service';
import { ManagerUsersService } from './services/manager-users.service';
import { PublicDevicesService } from './services/public-devices.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   AdminCompaniesService,
   AdminDashboardService,
   AdminDevicesService,
   AdminEmailsService,
   AdminOffersService,
   AdminPapersService,
   AdminPermissionsService,
   AdminProcurementsService,
   AdminRefarticlesService,
   AdminRolesService,
   AdminStatusService,
   AdminStocksService,
   AdminUsersService,
   AuthService,
   ManagerCompaniesService,
   ManagerDashboardService,
   ManagerDevicesService,
   ManagerEmailsService,
   ManagerOffersService,
   ManagerPapersService,
   ManagerPositionsService,
   ManagerProcurementsService,
   ManagerRefarticlesService,
   ManagerStocksService,
   ManagerUsersService,
   PublicDevicesService
  ],
})
export class ApiModule { }
