/* tslint:disable */
import { User } from './user';

/**
 */
export class Company {
    id?: number;
    name?: string;
    code?: string;
    city_code?: string;
    city_name?: string;
    contact?: string;
    address?: string;
    address_1?: string;
    address_2?: string;
    lat?: number;
    lng?: number;
    tax_code?: string;
    phone?: string;
    district?: string;
    logo?: string;
    extention?: string;
    image?: string;
    email?: string;
    appkey?: string;
    password?: string;
    confirm_password?: string;
    user?: User[];
    page?: number;
    limit?: number;
}
