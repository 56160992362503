/* tslint:disable */

/**
 */
export class Procurement {
    id?: number;
    client_code?: string;
    client_name?: string;
    client_address?: string;
    type_account?: string;
    email?: string;
    stock?: string;
    ref_paper?: string;
    paper?: string;
    provisioning?: number;
    time_limit?: number;
    check_order?: number;
    type?: string;
    unit?: string;
    total?: string;
    page?: number;
    limit?: number;
    level?: number;
}
