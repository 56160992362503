/* tslint:disable */
import { Stock } from './stock';

/**
 */
export class DeviceData {
    _id?: string;
    device_id?: number;
    lat?: number;
    lng?: number;
    battery?: number;
    distance?: number;
    distanceREF?: number;
    distanceREF_modify?: number;
    alert?: string;
    created_at?: string;
    last_uplink?: string;
    level?: number;
    stock_level?: number;
    stock_id?: number;
    stock?: Stock;
}
