/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Email } from '../models/email';


@Injectable()
export class AdminEmailsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   */
  listEmailResponse(): Observable<HttpResponse<Email[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Email[] = null;
        _body = _resp.body as Email[]
        return _resp.clone({body: _body}) as HttpResponse<Email[]>;
      })
    );
  }

  /**
   */
  listEmail(): Observable<Email[]> {
    return this.listEmailResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Created email object
   */
  createEmailResponse(body?: Email): Observable<HttpResponse<Email>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Email = null;
        _body = _resp.body as Email
        return _resp.clone({body: _body}) as HttpResponse<Email>;
      })
    );
  }

  /**
   * @param body - Created email object
   */
  createEmail(body?: Email): Observable<Email> {
    return this.createEmailResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module AdminEmailsService {
}
